import styled from "styled-components";

type Props = {
  align: "left" | "center";
  spacingHorizontal?: number;
}

export const PageTitle = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 32px;

  ${props => `
    text-align: ${props.align};
    padding: 0 ${props.spacingHorizontal ? props.spacingHorizontal + 'px' : 0} 32px;
    margin: ${props.align === "center" ? '0 auto' : '0'};
    align-items: ${props.align === "left" ? "flex-start" : props.align};

    h1, p {
      text-align: ${props.align};
    }  
  `};

  p {
    font-size: 16px;
    max-width: 480px;
  }
`;
