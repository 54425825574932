import React from "react";
import * as S from "./PageTitle.styled";

type Props = {
  heading: string;
  paragraph?: string;
  align?: "left" | "center";
  spacingHorizontal?: number;
}

const PageTitle = ({ heading, paragraph, align = "center", spacingHorizontal }: Props) => (
  <S.PageTitle align={align} spacingHorizontal={spacingHorizontal}>
    <h1>{heading}</h1>
    {paragraph && <p>{paragraph}</p>}
  </S.PageTitle>
);

export default PageTitle;
