import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as S from "./TextField.styled";

type Props = {
  value: string;
  label: string;
  disabled?: boolean;
  name?: string;
  type?: "text" | "email";
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  errorMessage?: string;
  fullWidth?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const TextField = ({
  value,
  label,
  disabled,
  name,
  type = "text",
  multiline,
  rows,
  error: hasError,
  errorMessage,
  fullWidth,
  onChange,
  onFocus,
  onBlur,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const currentLength = (value || '').length;
    setHasValue(currentLength > 0);
  }, [value])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  }, [onChange]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    onFocus && onFocus();
  }, [onFocus, setIsFocused]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    setIsTouched(true);
    onBlur && onBlur();
  }, [onBlur, setIsFocused]);

  const setFocus = useCallback(() => {
    const ref = multiline ? textareaRef : inputRef;
    if (ref && ref.current) { 
      ref.current.focus();
    }
  }, [inputRef, textareaRef, multiline]);

  const commonProps = {
    disabled,
    name,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  };

  const showError = useMemo(() => isTouched && hasError && !!errorMessage, [isTouched, hasError, errorMessage]);

  return (
    <S.Container fullWidth={fullWidth}>
      <S.TextField labelFloated={isFocused || hasValue} error={showError} onClick={setFocus}>
        {multiline ? (
          <textarea
            {...commonProps}
            rows={rows || 6}
            ref={textareaRef}
          />
        ) : (
          <input
            {...commonProps }
            type={type}
            ref={inputRef}
          />
        )}
        <label>{label}</label>
      </S.TextField>
      {showError && (
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
      )}
    </S.Container>
  );
};

export default TextField;