import styled from "styled-components";

const height = 48;
const fontSize = 14;
const fontSizeSmall = 10;
const lineHeight = 20;
const paddingHorizontal = 16;
const paddingVertical = (height - fontSize) / 2;
const labelPaddingHorizontal = 5;

type ContainerProps = {
  fullWidth?: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 20px;

  ${props => props.fullWidth && `
    width: 100%;
  `}
`;

type TextFieldProps = {
  labelFloated?: boolean;
  error?: boolean;
}

export const TextField = styled.div<TextFieldProps>`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0;
  border: 1px solid ${props => props.theme.colors.gray3};
  border-radius: 6px;
  background: ${props => props.theme.colors.white};

  label {
    position: absolute;
    border-radius: 6px;
    z-index: 3;
    color: ${props => props.theme.colors.gray2};
    background: transparent;
    font-weight: 400;
    pointer-events: none;
    text-shadow: none;
    transition: 200ms ease all;

    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    top: ${paddingVertical}px;
    left: ${paddingHorizontal - labelPaddingHorizontal}px;
    padding: 0 ${labelPaddingHorizontal}px;


    ${props => props.labelFloated && `
      top: -11px;
      font-size: ${fontSizeSmall}px;
      background: ${props.theme.colors.white};
    `}
  }

  input,
  textarea {
    z-index: 2;
    background-color: transparent;
    border-radius: 0;
    text-shadow: none;
    border: 0;
    color: ${props => props.theme.colors.black};
    width: 100%;
    border-radius: 6px;

    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    padding: ${paddingVertical}px ${paddingHorizontal}px;
  
    &:focus {
      outline: 0;
    }

    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
    }

    ${props => !props.labelFloated && `
      color: transparent;      
    `}
  }

  textarea {
    resize: none;
  }

  ${props => props.error && `
    border: 1px solid ${props.theme.colors.error};
    
    label {
      color: ${props.theme.colors.error};
    }
  `}
`;

export const ErrorMessage = styled.span`
  display: block;
  color: ${props => props.theme.colors.error};
  padding: 2px ${paddingHorizontal}px;
  font-size: ${fontSizeSmall}px;
  line-height: 150%;
`;
