import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../../components/layout/Layout";
import PageWrapper from "../../components/layout/PageWrapper";
import PageTitle from "../../components/layout/PageTitle";
import TextField from "../../components/ui-kits/TextField";
import Button from "../../components/ui-kits/Button";

const Form = styled.form`
  max-width: 588px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 173px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const LegalNote = styled.span`
  margin-top: 20px;
  display: block;
  max-width: 588px;
  width: 100%;
  font-size: 10px;
  line-height: 150%;
  color: ${props => props.theme.colors.gray1};
`;

type FormField = {
  value: string;
  error: boolean;
  errorMessage: string;
};
type ContactForm = {
  name: FormField;
  email: FormField;
  subject: FormField;
  message: FormField;
};
type ContactFormFieldName = keyof ContactForm;

const defaultFormData: ContactForm = {
  name: {
    value: "",
    error: true,
    errorMessage: ""
  },
  email: {
    value: "",
    error: true,
    errorMessage: ""
  },
  subject: {
    value: "",
    error: true,
    errorMessage: ""
  },
  message: {
    value: "",
    error: true,
    errorMessage: ""
  }
}

const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const hasFormErrors = (form: ContactForm) => {
  const fields = Object.keys(form) as ContactFormFieldName[];  
  const fieldsWithError = fields.filter((item) => form[item].error === true);
  return fieldsWithError.length > 0;
}

const ContactPage = ({ location }: PageProps) => {
  const { t } = useTranslation();

  const [form, setForm] = useState<ContactForm>(defaultFormData);
  const [hasErrors, setHasErrors] = useState<boolean>(true);

  useEffect(() => {
    setHasErrors(hasFormErrors(form));
  }, [form, setForm]);

  const onChangeField = useCallback((fieldName: ContactFormFieldName, value: string) => {
    const trimmedValue = value.trim();
    let error = false;
    let errorMessage = "";

    if (!trimmedValue) {
      error = true;
      errorMessage = t("contact.form.errors.required");
    } else if (fieldName === "email" && !isValidEmail(trimmedValue)) {
      error = true;
      errorMessage = t("contact.form.errors.invalidEmail");
    }

    setForm(data => {
      return ({
        ...data,
        [fieldName]: {
          value,
          error,
          errorMessage
        }
      }) 
    })
  }, [setForm]);

  const clear = useCallback(() => {
    setForm(defaultFormData);
  }, [setForm])

  const submit = useCallback(async () => {
    try {
      const formData = new FormData();

      formData.append("name", form.name.value);
      formData.append("mail", form.email.value);
      formData.append("subject", form.subject.value);
      formData.append("msg", form.message.value);

      /**
        * TODO
        * 1. post 
        * 2. success toast
        */
      
      clear();
    } catch (e) {
      // TODO error toast + error message
    }
  }, [form, clear])

  return (
    <Layout location={location}>
      <PageWrapper>
        <PageTitle
          heading={t("contact.heading")}
          paragraph={t("contact.paragraph")}
        />
        <Form>
          <TextField
            name="name" label={t("contact.form.fields.name")}
            value={form.name.value}
            error={form.name.error}
            errorMessage={form.name.errorMessage}
            onChange={value => onChangeField("name", value)}
          />
          <TextField
            name="email" label={t("contact.form.fields.email")}
            value={form.email.value}
            error={form.email.error}
            errorMessage={form.email.errorMessage}
            onChange={value => onChangeField("email", value)}
          />
          <TextField
            name="subject" label={t("contact.form.fields.subject")}
            value={form.subject.value}
            error={form.subject.error}
            errorMessage={form.subject.errorMessage}
            onChange={value => onChangeField("subject", value)}
          />
          <TextField
            name="message" label={t("contact.form.fields.msgContent")}
            value={form.message.value}
            error={form.message.error}
            errorMessage={form.message.errorMessage}
            onChange={value => onChangeField("message", value)}
            multiline
          />
          <ButtonWrapper>
            <Button
              onClick={submit}
              disabled={hasErrors}
              size="M"
              fullWidth
            >
              {t("contact.form.submit")}
            </Button>
          </ButtonWrapper>
          <LegalNote>{t("contact.legalNote")}</LegalNote>
        </Form>
      </PageWrapper>
    </Layout>
  );
};

export default ContactPage;